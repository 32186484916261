<template>
	<section class="projects bg--mono-white py-5">
		<div class="container">
			<h2 class="projects-title mb-5 font-responsive">Projects</h2>

			<div class="projects-row row justify-content-center" :class="projects.length % 2 !== 0 ? 'is-odd' : 'is-even'">
				<div class="col-12 col-md-5 project-item" v-for="project in projects" :key="project.name">
					<Project class="mb-5" :data="project"/>
				</div>
			</div>
		</div>
	</section>

	<component v-for="(section, index) in content" :key="index" :is="section.name" :data="section"></component>
</template>

<script>
	// eslint-disable-next-line no-unused-vars
	import ProjectObject from '@/assets/projects';
	import ProjectsContent from '@/assets/pages/Projects.json';

	export default {
		name: 'Projects',

		computed: {
			console: () => console,
		},

		data() {
			return {
				projects: []
			}
		},

		created() {
			this.projects = Object.values( ProjectObject );
			this.content = ProjectsContent.content;
		},

		mounted() {
			this.$functions.sectionPadding();
		}
	}
</script>

<style lang="scss" scoped>
	.projects {
		position: relative;
		z-index: 0;

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			bottom: 0; 
			left: 0;
			width: 100%;
			height: 50%;
			background: rgb(255,255,255);
			background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
		}

		&:after {
			content: '';
			position: absolute;
			z-index: -2;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url('../assets/images/svgs/ears-tiled_accent.svg?inline');
			opacity: 0.033;
			background-size: 200px;
		}

		@include media-breakpoint-up(xxl) {
			.project-item {
				margin-left: $spacer * 4;
				margin-right: $spacer * 4;
			}
		}
	}

	.projects-title {
		text-align: center;
	}

	.projects-row {
		margin-bottom: -$spacer * 4;

		@include media-breakpoint-up(md) {
			.project-item:nth-of-type(even) {
				margin-top: $spacer * 6;
			}

			.project-item:nth-of-type(odd):not(:first-child) {
				margin-top: - $spacer * 6;
			}
		}

		@include media-breakpoint-up(lg) {
			.project-item:nth-of-type(even) {
				margin-top: $spacer * 10;
			}

			.project-item:nth-of-type(odd):not(:first-child) {
				margin-top: - $spacer * 10;
			}
		}

		&.is-odd {
			&:after {
				content: '';
				display: inline-block;
				@extend .col-md-5;
			}
		}
	}
</style>